import React, { useState, useEffect } from 'react'
import BaseCalculator from '../basecalculator'
import MainCalculator from '../maincalculator'
import UpsideCalculator from '../upsidecalculator'
import { convertToCurrency } from '../../utils/funcs'

const ProfitCalculator = () => {
    const [productsData, setProductsData] = useState([])

    useEffect(() => {
        setProductsData([
            {
                name: 'Hoodie',
                minPrice: 120,
                minProfit: 40,
                userInpPrice: convertToCurrency(120),
                userPrice: 120,
                itemsSold: 0,
                ratio: 3.5,
                userProfit: 40,
                userTotalProfit: 0,
                upsideQuantity: 0,
                userUpsideProfit: 0,
            },
            {
                name: 'Long-Sleeve',
                minPrice: 90,
                minProfit: 33,
                userInpPrice: convertToCurrency(90),
                userPrice: 90,
                itemsSold: 0,
                ratio: 2,
                userProfit: 33,
                userTotalProfit: 0,
                upsideQuantity: 0,
                userUpsideProfit: 0,
            },
            {
                name: 'T-Shirt',
                minPrice: 80,
                minProfit: 30,
                userInpPrice: convertToCurrency(80),
                userPrice: 80,
                itemsSold: 0,
                ratio: 2.5,
                userProfit: 30,
                userTotalProfit: 0,
                upsideQuantity: 0,
                userUpsideProfit: 0,
            },
            {
                name: 'Pants',
                minPrice: 90,
                minProfit: 30,
                userInpPrice: convertToCurrency(90),
                userPrice: 90,
                itemsSold: 0,
                ratio: 1.85,
                userProfit: 30,
                userTotalProfit: 0,
                upsideQuantity: 0,
                userUpsideProfit: 0,
            },
        ])
    }, [])

    return (
        <div className="profitCalculator">
            <BaseCalculator data={productsData} updateData={setProductsData} />
            <MainCalculator data={productsData} updateData={setProductsData} />
            <UpsideCalculator data={productsData} />
        </div>
    )
}

export default ProfitCalculator
