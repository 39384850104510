import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

import styles from './WorksPageNav.module.scss'

const WorksPageNav = ({ selected }) => {
    const seo = selected.replace(/-/g, ' ')
    return (
        <>
            <h1 className={styles.seo}>{seo}</h1>
            <nav className={`${styles.worksPageNav} nav row`}>
                <div className={`${styles.buttonPadding} ${styles.colWorksPage}`}>
                    <Link href="[id]" as="how-it-works">
                        <a className={`${styles.navBtn} btn navBtn ${selected === 'how-it-works' ? ' selected' : ''}`}>
                            How it works
                        </a>
                    </Link>
                </div>
                <div className={`${styles.buttonPadding} ${styles.colWorksPage}`}>
                    <Link href="[id]" as="what-you-need-to-do">
                        <a className={`${styles.navBtn} btn navBtn ${selected === 'what-you-need-to-do' ? ' selected' : ''}`}>
                            What you need to do
                        </a>
                    </Link>
                </div>
                <div className={`${styles.buttonPadding} ${styles.colWorksPage}`}>
                    <Link href="[id]" as="how-to-make-money">
                        <a className={`${styles.navBtn} btn navBtn ${selected === 'how-to-make-money' ? ' selected' : ''}`}>
                            How to make money
                        </a>
                    </Link>
                </div>
                <div className={`${styles.buttonPadding} ${styles.colWorksPage}`}>
                    <Link href="[id]" as="how-to-get-discovered">
                        <a className={`${styles.navBtn} btn navBtn ${selected === 'how-to-get-discovered' ? ' selected' : ''}`}>
                            How to get discovered
                        </a>
                    </Link>
                </div>
            </nav>
        </>
    )
}

WorksPageNav.propTypes = {
    selected: PropTypes.string.isRequired,
}

export default WorksPageNav
