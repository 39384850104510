import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import parse from 'html-react-parser'
import Link from 'next/link'
import { PATHS } from '../../utils/vars'

import styles from './Waitlist.module.scss'

const Waitlist = ({ background, type, text }) => {
    const [userEmail, setUserEmail] = useState('')
    const [showLink, setShowLink] = useState(false)

    const appendValues = async ({ email }) => {
        try {
            const res = await axios({
                method: 'POST',
                url: `/api/waitlist?email=${email}&type=${type}`,
            })
            if (res.status === 200) {
                setShowLink(true)
            }
        } catch {
            setUserEmail('')
        }
    }

    return (
        <form
            className={styles.waitlist}
            style={{ backgroundImage: `url(${background})` }}
            onSubmit={(e) => {
                appendValues({ email: userEmail })
                e.preventDefault()
            }}
        >
            {
                showLink ? (
                    <div className={styles.demoRow}>
                        <div className={styles.demoCol}>
                            <button type="button" className={`${styles.demoLink} btn`}>
                                <Link href={PATHS.DEMO_PAGE}>
                                    <a>
                                        <span>See the demo</span>
                                    </a>
                                </Link>
                            </button>
                        </div>
                    </div>
                ) : [(
                    <div className={styles.formRow} key="email-textbox">
                        <label htmlFor="email" className={`${styles.formElemCont} padding ${styles.inputRow}`}>
                            <input
                                id="email"
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                                type="email"
                                className="rounded-0 text-center"
                                placeholder="email address"
                                aria-label="email address"
                                required
                            />
                            <p className="sr-only">Email Input</p>
                        </label>
                    </div>
                ), (
                    <div className={styles.formRow} key="email-button">
                        <div className={`${styles.formElementButton} padding ${styles.buttonRow}`}>
                            <button
                                type="submit"
                                className={`${styles.buttonSize} btn`}
                            >
                                {parse(text)}
                            </button>
                        </div>
                    </div>
                ),
                ]
            }
        </form>
    )
}

Waitlist.propTypes = {
    background: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
}

export default Waitlist
