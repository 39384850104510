import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { convertToCurrency, convertToCurrencySmall } from '../../utils/funcs'
import arrow from '../../assets/arrow.svg'

import styles from './UpsideCalculator.module.scss'

const UpsideCalculator = ({ data }) => {
    const [open, setOpen] = useState(true)

    let grandTotal = 0
    data.forEach(({ userUpsideProfit }) => {
        grandTotal += userUpsideProfit
    })
    return (
        <div style={{ maxHeight: open ? '600px' : '53px' }} className={styles.upsideCalculator}>
            <button type="button" onClick={() => setOpen(!open)} className={styles.accordionButton}>
                <h4>Upside Calculator</h4>
                <img alt="" style={{ transform: open ? 'rotate(90deg)' : 'rotate(0deg)' }} src={arrow} className={`arrowToggle${open ? ' open' : ''}`} />
            </button>
            <div className="collapse">
                <div className={styles.products}>
                    <p className={styles.desc}>
                        What would your profit look like if you sold more? You keep the upside
                        with any higher price you set, and the more you sell, the more you
                        capitalize on your upside pricing.
                    </p>
                    <div className={`${styles.tableHeaders} row`}>
                        <div className="col" />
                        <div className="col">
                            <h5 className={styles.textHeader}>Items sold</h5>
                        </div>
                        <div className={`col ${styles.displayLrg}`}>
                            <h4>Your Price</h4>
                        </div>
                        <div className={`col ${styles.padLeft}`}>
                            <h4>Your Profit</h4>
                        </div>
                    </div>
                    {
                        data.map(({
                            name, userPrice, upsideQuantity, userUpsideProfit,
                        }) => (
                            <div key={name} className={`row ${styles.productInfo}`}>
                                <div className={`colQuarterThirdsLg ${styles.name}`}>
                                    <p>{name}</p>
                                </div>
                                <div className="colQuarterThirdsLg">
                                    <p className={styles.price}>{upsideQuantity}</p>
                                </div>
                                <div className={`colQuarterThirdsLg ${styles.displayLrg}`}>
                                    <p className={styles.price}>{convertToCurrency(userPrice)}</p>
                                </div>
                                <div className={`colQuarterThirdsLg ${styles.currency}`}>
                                    <p className={styles.displayLrg}>
                                        {convertToCurrency(userUpsideProfit)}
                                    </p>
                                    <p className={styles.displaySm}>
                                        {convertToCurrencySmall(userUpsideProfit)}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                    <div className={styles.totalDivider} />
                    {
                        data.length > 0 && (
                            <p className={`row ${styles.total}`}>{convertToCurrency(grandTotal)}</p>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

UpsideCalculator.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default UpsideCalculator
