import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { ERROR_MSGS } from '../../utils/vars'
import brain from '../../assets/brain.svg'
import MetaHeaders from '../metaheaders'

import styles from './Errors.module.scss'

const Errors = ({ status }) => {
    const rNum = Math.floor(Math.random() * ERROR_MSGS.length)

    return (
        <div className={styles.errorMsgs}>
            <MetaHeaders
                title={`${status} Error: ${status === 404 ? 'Page Not Found' : 'Internal Server Error'}`}
                description={`${status === 404 ? 'Sorry, we are having trouble displaying this page' : 'Sorry, Something is up with our servers'}`}
            />
            <div className="container">
                <h2 className={styles.title}>{ status === 404 ? 'Page not found' : 'Internal Server Error' }</h2>
                {
                    status === undefined
                        ? (<h1 className={styles.errorCodeUndefined}>{`${status} Error`}</h1>)
                        : (<h1 className={styles.errorCode}>{`${status} Error`}</h1>)
                }
                <p className={styles.start}>Oops...</p>
                <p className={styles.start}>Our Robot forgot to</p>
                <p className={styles.errorMsg}>{ERROR_MSGS[rNum]}</p>
                <img alt="" src={brain} width="175px" height="175px" />
                <p className={styles.contact}>
                    In the meantime, head back to the&nbsp;
                    <Link href="/">
                        <a>homepage</a>
                    </Link>
                    &nbsp;or&nbsp;
                    <a href={`mailto:human@urbancoolab.com?subject=${status} Error`}>
                        contact our humans
                    </a>
                    &nbsp;for help
                </p>
            </div>
        </div>
    )
}

Errors.propTypes = {
    status: PropTypes.number.isRequired,
}

export default Errors
