import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import arrow from '../../assets/next-arrow.svg'

import styles from './InfoBox.module.scss'

const InfoBox = ({
    num, title, description, disableArrow,
}) => (
    <div className={styles.infoBox}>
        <p className={styles.num}>{`0${num}`}</p>
        <h2 className={styles.title}>
            {parse(title)}
        </h2>
        <p className={styles.description}>{description}</p>
        { !disableArrow && <img className={styles.nextArrow} alt="" src={arrow} />}
    </div>
)

InfoBox.defaultProps = {
    disableArrow: false,
}

InfoBox.propTypes = {
    num: PropTypes.number.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    description: PropTypes.string.isRequired,
    disableArrow: PropTypes.bool,
}

export default InfoBox
