import React from 'react'
import PropTypes from 'prop-types'
import ReactVimeo from '@u-wave/react-vimeo'
import Layout from '../../components/layout'
import ContentfulClient from '../../contentful'
import { PAGES_ID_MAP, PATHS } from '../../utils/vars'
import WorksPageNav from '../../components/workspagenav'
import InfoBox from '../../components/infobox'
import Waitlist from '../../components/waitlist'
import MetaHeaders from '../../components/metaheaders'
import Errors from '../../components/errors'
import ProfitCalculator from '../../components/profitcalculator'
import { getYouTubeId } from '../../utils/funcs'

import styles from './UserKnowledge.module.scss'

const Post = ({
    error,
    metaTitle,
    metaDescription,
    name,
    info,
    infoVideoTitle,
    infoVideo,
    infoBoxes,
    waitlistBackground,
}) => (
    <div className={styles.userKnowledge}>
        <MetaHeaders title={metaTitle} description={metaDescription} />
        <Layout>
            {
                error ? <Errors status={error} /> : (
                    <div className={`container ${styles.userKnowledge}`}>
                        <WorksPageNav selected={name} />
                        <div className={`row ${styles.center}`}>
                            <div className="colHalfLg">
                                {
                                    infoBoxes.map(({ title, description }, idx) => (
                                        <InfoBox
                                            key={title}
                                            num={idx + 1}
                                            title={title}
                                            description={description}
                                            disableArrow={idx + 1 === infoBoxes.length}
                                        />
                                    ))
                                }
                            </div>
                            <div className={`${styles.flexColRight} padding`}>
                                <p className={styles.info}>{info}</p>
                                <div className={styles.videoPlayer}>
                                    <iframe title={infoVideoTitle} width="100%" height="315" src={`https://www.youtube.com/embed/${getYouTubeId(infoVideo)}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                </div>
                                <p className={styles.questions}>
                                    <span>Have more questions?</span>
                                    <a href="mailto:human@urbancoolab.com?subject=Contacting humans">ask our humans</a>
                                </p>
                            </div>
                        </div>
                        {
                            name === PATHS.HOW_TO_MAKE_MONEY && (
                                <div className={`${styles.center} row`}>
                                    <div className={`${styles.flexColCalc} padding`}>
                                        <ProfitCalculator />
                                    </div>
                                </div>
                            )
                        }
                        {
                            name === PATHS.HOW_IT_WORKS && (
                                <ReactVimeo
                                    video="https://vimeo.com/429304850/dc0ee8086e"
                                    responsive
                                />
                            )
                        }
                        <Waitlist background={waitlistBackground} type="early-access" text="I’m ready,<br/> somebody contact me" />
                    </div>
                )
            }
        </Layout>
    </div>
)

Post.getInitialProps = async (context) => {
    const { id } = context.query
    if (!PAGES_ID_MAP[id]) {
        context.res.statusCode = 404
        return {
            error: 404,
            metaTitle: '',
            metaDescription: '',
            name: '',
            info: '',
            infoVideoTitle: '',
            infoVideo: '',
            infoBoxes: [],
            waitlistBackground: '',
        }
    }

    const res = await ContentfulClient.getEntry(PAGES_ID_MAP[id])
    return {
        ...res.fields,
        waitlistBackground: res.fields.waitlistBackground.fields.file.url,
        infoBoxes: res.fields.infoBoxes.map((item) => item.fields),
    }
}

Post.defaultProps = {
    error: null,
}

Post.propTypes = {
    error: PropTypes.number,
    metaTitle: PropTypes.string.isRequired,
    metaDescription: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    infoVideoTitle: PropTypes.string.isRequired,
    infoVideo: PropTypes.string.isRequired,
    infoBoxes: PropTypes.arrayOf(PropTypes.object).isRequired,
    waitlistBackground: PropTypes.string.isRequired,
}

export default Post
