import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { convertToCurrency, updateCalculations } from '../../utils/funcs'
import { PATHS } from '../../utils/vars'

import styles from './BaseCalculator.module.scss'

const BaseCalculator = ({ data, updateData }) => (
    <div className={`${styles.baseCalculator} row`}>
        <div className="colHalfLg padding">
            <div className={styles.labels}>
                <h4 className={styles.heading}>Basic Profit</h4>
                <p className={styles.subHeading}>(based on our recommended selling price)</p>
            </div>
            <div className={`${styles.tableHeaders} row`}>
                <div className="colQuarter" />
                <div className="colHalf">
                    <h5 className={styles.price}>MSRP*</h5>
                </div>
                <div className="colQuarter">
                    <h5 className={styles.profit}>Profit</h5>
                </div>
            </div>
            {
                data.map(({ name, minPrice, minProfit }) => (
                    <div key={name} className={`${styles.productInfo} row`}>
                        <div className="col">
                            <p>{name}</p>
                        </div>
                        <div className="col">
                            <p className={`${styles.textCenter}`}>{convertToCurrency(minPrice)}</p>
                        </div>
                        <div className="col">
                            <p className={styles.textRight}>{convertToCurrency(minProfit)}</p>
                        </div>
                    </div>
                ))
            }
        </div>
        <div className="colHalfLg padding">
            <div className={styles.labels}>
                <h4 className={`${styles.heading} ${styles.headingRight}`}>
                    Maximize your profit
                </h4>
            </div>
            <div className={`${styles.tableHeaders} row`}>
                <div className={`colQuarter ${styles.displayNone}`} />
                <div className={`colHalf ${styles.textAlign}`}>
                    <h5 className={styles.textHeader}>Your price</h5>
                </div>
                <div className={`colQuarter ${styles.flexLarge}`}>
                    <h5 className={styles.textRight}>Your profit</h5>
                </div>
            </div>
            {
                data.map(({
                    name,
                    upsideQuantity,
                    userInpPrice,
                    minPrice,
                    minProfit,
                    userProfit,
                    itemsSold,
                    ratio,
                }, idx) => (
                    <div key={name} className={`${styles.productInfo} row`}>
                        <div className={`${styles.productName} col`}>
                            <p>{name}</p>
                        </div>
                        <div className={`col ${styles.inputAlign}`}>
                            <label htmlFor={`priceInput ${name}`}>
                                <input
                                    id={`priceInput ${name}`}
                                    type="text"
                                    autoComplete="off"
                                    className={styles.priceInput}
                                    value={`${userInpPrice.includes('$') ? '' : '$'}${userInpPrice}`}
                                    onMouseDown={() => {
                                        const newData = data
                                        newData[idx] = {
                                            ...newData[idx],
                                            userInpPrice: '',
                                            userPrice: minPrice,
                                        }
                                        updateData([...newData])
                                    }}
                                    onChange={(e) => {
                                        const val = e.target.value.replace('$', '').replace(',', '')
                                        const newData = data

                                        const noDecimal = !val.includes('.') && val.length <= 6
                                        const segments = val.split('.')
                                        const withDecimal = val.includes('.') && segments[0].length <= 6 && segments[1].length <= 2
                                        const correctFormat = noDecimal || withDecimal

                                        if (correctFormat && val.match(/^[-+]?\d*\.?\d*$/g)) {
                                            newData[idx].userInpPrice = val
                                            const newUserPrice = parseFloat(val)
                                            if (newUserPrice >= minPrice) {
                                                const newCalculations = updateCalculations({
                                                    userPrice: newUserPrice,
                                                    minPrice,
                                                    minProfit,
                                                    itemsSold,
                                                    ratio,
                                                })
                                                newData[idx] = {
                                                    ...newData[idx],
                                                    userPrice: newUserPrice,
                                                    ...newCalculations,
                                                }
                                            }
                                        }

                                        updateData([...newData])
                                    }}
                                    onBlur={() => {
                                        const newData = data
                                        const rawPrice = userInpPrice.replace('$', '').replace(',', '')
                                        const newPrice = rawPrice === '' ? 0 : parseFloat(rawPrice)

                                        newData[idx] = {
                                            ...newData[idx],
                                            userInpPrice: convertToCurrency(
                                                Math.max(newPrice, minPrice),
                                            ),
                                        }

                                        if (newPrice < minPrice) {
                                            newData[idx].userProfit = minProfit
                                            newData[idx].userUpsideProfit = upsideQuantity
                                            * minPrice
                                            newData[idx].userTotalProfit = itemsSold * minProfit
                                        }

                                        updateData([...newData])
                                    }}
                                />
                                <p className="sr-only">Price Input</p>
                            </label>
                        </div>
                        <div className={`col ${styles.userProfit}`}>
                            <p>{convertToCurrency(userProfit)}</p>
                        </div>
                    </div>
                ))
            }
        </div>
        <div className="col">
            <p className={styles.disclaimer}>* Minimum Suggested Retail Price</p>
            <p className={styles.disclaimer}>
                ** This profit calculator is an indicative tool for the user to gauge
                potential profits levels. Please note that urbancoolab does not
                guarantee any profits or sales, nor does urbancoolab make representations
                or warranties as to any profits or sales. To review, please see
                {' '}
                <Link href={PATHS.TERMS_CONDITIONS}>
                    <a>
                        urbancoolab’s full terms and conditions here.
                    </a>
                </Link>
            </p>
        </div>
    </div>
)

BaseCalculator.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    updateData: PropTypes.func.isRequired,
}

export default BaseCalculator
