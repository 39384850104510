import React, { useState } from 'react'
import PropTypes from 'prop-types'
import arrow from '../../assets/arrow.svg'
import { convertToCurrency, convertToCurrencySmall, updateCalculations } from '../../utils/funcs'

import styles from './MainCalculator.module.scss'

const MainCalculator = ({ data, updateData }) => {
    const [open, setOpen] = useState(true)

    let grandTotal = 0
    data.forEach(({ userTotalProfit }) => {
        grandTotal += userTotalProfit
    })
    return (
        <div style={{ maxHeight: open ? '600px' : '53px' }} className={styles.mainCalculator}>
            <button type="button" onClick={() => setOpen(!open)} className={styles.accordionButton}>
                <h4>Profit Calculator</h4>
                <img alt="" style={{ transform: open ? 'rotate(90deg)' : 'rotate(0deg)' }} src={arrow} className={`arrowToggle${open ? ' open' : ''}`} />
            </button>
            <div className="collapse">
                <div className={styles.products}>
                    <div className={`${styles.tableHeaders} row`}>
                        <div className="col" />
                        <div className="col">
                            <h5 className={styles.textHeader}>Items sold</h5>
                        </div>
                        <div className={`col ${styles.displayLrg}`}>
                            <h4>Your Price</h4>
                        </div>
                        <div className={`col ${styles.padLeft}`}>
                            <h4>Your Total Profit</h4>
                        </div>
                    </div>
                    {
                        data.map(({
                            name,
                            ratio,
                            itemsSold,
                            userPrice,
                            minPrice,
                            minProfit,
                            userTotalProfit,
                        }, idx) => (
                            <div key={name} className={`row ${styles.productInfo}`}>
                                <div className={`colQuarterThirdsLg ${styles.name}`}>
                                    <p>{name}</p>
                                </div>
                                <div className="colQuarterThirdsLg">
                                    <label htmlFor={`itemsSold ${name}`}>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            id={`itemsSold ${name}`}
                                            maxLength={8}
                                            value={itemsSold}
                                            className={styles.itemsSold}
                                            onMouseDown={() => {
                                                const newData = data
                                                newData[idx] = {
                                                    ...newData[idx],
                                                    itemsSold: '',
                                                }
                                                updateData([...newData])
                                            }}
                                            onChange={(e) => {
                                                const val = e.target.value
                                                const newData = data
                                                if (val.match(/^\d+([^.,])?$/g)) {
                                                    const newItemsSold = parseInt(val, 10)
                                                    const newCalculations = updateCalculations({
                                                        userPrice,
                                                        minPrice,
                                                        minProfit,
                                                        itemsSold: newItemsSold,
                                                        ratio,
                                                    })
                                                    newData[idx] = {
                                                        ...newData[idx],
                                                        itemsSold: newItemsSold,
                                                        ...newCalculations,
                                                    }
                                                    updateData([...newData])
                                                }

                                                if (val.length === 0) {
                                                    newData[idx] = {
                                                        ...newData[idx],
                                                        itemsSold: val,
                                                    }
                                                    updateData([...newData])
                                                }
                                            }}
                                            onBlur={() => {
                                                if (itemsSold.length === 0) {
                                                    const newData = data
                                                    newData[idx] = {
                                                        ...newData[idx],
                                                        itemsSold: 0,
                                                        upsideQuantity: 0,
                                                        userUpsideProfit: 0,
                                                        userTotalProfit: 0,
                                                    }
                                                    updateData([...newData])
                                                }
                                            }}
                                            aria-label="Items Sold"
                                        />
                                        <p className="sr-only">Items Sold</p>
                                    </label>
                                </div>
                                <div className={`colQuarterThirdsLg ${styles.displayLrg}`}>
                                    <p className={styles.price}>{convertToCurrency(userPrice)}</p>
                                </div>
                                <div className={`colQuarterThirdsLg ${styles.currency}`}>
                                    <p className={styles.displayLrg}>
                                        {convertToCurrency(userTotalProfit)}
                                    </p>
                                    <p className={styles.displaySm}>
                                        {convertToCurrencySmall(userTotalProfit)}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                    <div className={styles.totalDivider} />
                    {
                        data.length > 0 && (
                            <p className={`row ${styles.total}`}>{convertToCurrency(grandTotal)}</p>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

MainCalculator.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    updateData: PropTypes.func.isRequired,
}

export default MainCalculator
